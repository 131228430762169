import React from 'react'

import "./index.scss";

const Footer = ({scrollStatus}) => {
  return (
    <div className="footer-div">
      <footer>
        <p>All Copyright Reserved. @ Shiva Tiwari </p>
      </footer>
    </div>
      
  )
}

export default Footer
